/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import { FiEdit3 } from "react-icons/fi";

import "./style.sass";

import api from "../../util/api";
import Menu from "../../components/Menu";
import EditProfileForm from "../../components/EditProfileForm";
import ProfilePreview from "../../components/ProfilePreview";
import EditProfileModal from "../../components/EditProfileModal";

const profiles = {
  enneagram: [
    ["Perfeccionista", "perfectionist"],
    ["Ajudante", "helper"],
    ["Vencedor", "achiever"],
    ["Intenso", "romantic"],
    ["Analítico", "investigator"],
    ["Precavido", "loyalist"],
    ["Otimista", "enthusiast"],
    ["Poderoso", "challanger"],
    ["Mediador", "mediator"],
  ],
  disc: [
    ["Dominante", "dominance"],
    ["Influente", "influence"],
    ["Estável", "steadiness"],
    ["Cauteloso", "conscientiousness"],
  ],
};

export default function Edit({ user, color, changeColorScheme }) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [test, setTest] = useState("enneagram");
  const [profile, setProfile] = useState(profiles[test][0][1]);
  const [results, setResults] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [updateProfile, setUpdateProfile] = useState(null);
  const [decodedDescription, setDecodedDescription] = useState("");

  function decodeString(str) {
    return decodeURIComponent(escape(str));
  }

  useEffect(() => {
    if (!firstLoad) return;

    const prevFilters = sessionStorage.getItem("profile");
    if (prevFilters) {
      const filters = JSON.parse(prevFilters);
      setTest(filters.test);
      setProfile(filters.profile);
    }
    setFirstLoad(false);
  }, [firstLoad]);

  useEffect(() => {
    const profileFilters = JSON.stringify({ test, profile });
    sessionStorage.setItem("profile", profileFilters);
  }, [profile, test]);

  useEffect(() => {
    let mounted = true;

    api
      .get(`/profile/${test}`)
      .then((res) => {
        if (mounted) {
          setResults(res.data.profiles);
        }
      })
      .catch((err) => console.warn(err));

    return () => (mounted = false);
  }, [test]);

  useEffect(() => {
    if (!updateProfile) return;

    let mounted = true;

    api
      .patch(`/profile/${test}`, updateProfile, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      })
      .then(() => {
        api
          .get(`/profile/${test}`)
          .then((res) => {
            if (mounted) {
              setResults(res.data.profiles);
            }
          })
          .catch((err) => console.warn(err));
      })
      .catch((err) => console.warn(err));

    return () => (mounted = false);
  }, [test, updateProfile]);

  function handleProfileChange(nextProfile) {
    setProfile(nextProfile);
  }

  function handleProfileModalChange(values) {
    if (values) {
      setUpdateProfile({ ...values, profile });
    }
    setShowModal(false);
  }

  function handleTestChange(newTest) {
    if (newTest !== test) {
      setTest(newTest);
      setProfile(profiles[newTest][0][1]);
    }
  }

  const result = results ? results.find((r) => r.profile === profile) : {};

  useEffect(() => {
    if (!result) return;

    // Decodificar a descrição
    const decoded = decodeString(result.description);
    setDecodedDescription(decoded);

    // Outras operações relacionadas ao `result`, se necessário
  }, [result]);

  return (
    <div className="edit bg-medium">
      <Menu select="edit" user={user} changeColorScheme={changeColorScheme} />
      <div className="edit-content">
        <div className="page bg-light">
          <header className="page-header dark">Página de resultados</header>
          <section className="page-section border-light">
            <EditProfileForm
              value={{ test, profile }}
              color={color}
              onProfileChange={setProfile}
              onTestChange={handleTestChange}
              profiles={profiles}
            />
            <button
              className="edit-button border-medium border-medium-hover"
              onClick={() => setShowModal(true)}
            >
              <FiEdit3 size="20" color={color} />
              <span className="button-name dark">Editar página</span>
            </button>
          </section>
          <section>
            <ProfilePreview
              profile={profile}
              test={test}
              profiles={profiles}
              result={result}
              description={decodedDescription}
              onProfileChange={handleProfileChange}
            />
          </section>
        </div>
      </div>
      {showModal && (
        <EditProfileModal
          videoURL={result.video_url}
          communityURL={result.community_url}
          description={result.description}
          onProfileChange={handleProfileModalChange}
          profile={profiles[test].find((p) => p[1] === profile)[0]}
          color={color}
        />
      )}
    </div>
  );
}
