import React from "react";
import { FiX } from "react-icons/fi";

import "./style.sass";

export default function UserDeleteModal({ user, onUserAction, color }) {
  function handleModalClick(e) {
    if (e.target.className === "user-delete-modal") {
      onUserAction && onUserAction(false);
    }
  }

  return (
    <div className="user-delete-modal" onClick={handleModalClick}>
      <div className="modal-wrapper bg-light">
        <div className="modal-container">
          <div
            className="close-button"
            onClick={() => onUserAction && onUserAction(false)}
            title="Fechar"
          >
            <FiX size="24" color={color} />
          </div>
          <header className="dark">Excluir usuário</header>
          <div className="question medium">
            Tem certeza que deseja excluir o usuário
            <span className="username medium"> {user.name}</span>?
          </div>
          <button
            className="dark border-medium border-medium-hover"
            onClick={() => onUserAction && onUserAction(true)}
          >
            Excluir
          </button>
        </div>
      </div>
    </div>
  );
}
