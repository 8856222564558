import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Results from "./pages/Results";
import Edit from "./pages/Edit";
import Users from "./pages/Users";
import Logout from "./pages/Logout";
import PasswordRecovery from "./pages/PasswordRecovery";
import RecoveryCode from "./pages/RecoveryCode";
import PasswordChange from "./pages/PasswordChange";
import Home from "./pages/Home";

import validateUserSession from "./util/validateUserSession";
import colors from "./config/colors";

export default function Routes() {
  const [user, setUser] = useState(null);
  const [colorScheme, setColorScheme] = useState(
    localStorage.getItem("colorScheme") || "default"
  );

  useEffect(() => {
    validateUserSession().then(() => {
      const user = sessionStorage.getItem("user");
      if (user) {
        setUser(JSON.parse(user));
        if (window.location.pathname === "/") {
          window.location = "/resultados";
        }
        return;
      }

      if (window.location.pathname !== "/") {
        window.location = "/";
      }
    });
  }, []);

  useEffect(() => {
    sessionStorage.removeItem("results");
    sessionStorage.removeItem("users");
    sessionStorage.removeItem("filters");
    sessionStorage.removeItem("profile");
  }, []);

  const color = colors[colorScheme] || colors.default;
  const className = colorScheme
    ? `${colorScheme}-color-scheme`
    : "default-color-scheme";

  document.querySelector("#root").setAttribute("class", className);

  function handleColorSchemeChange(scheme) {
    if (!colors[scheme]) return;

    localStorage.setItem("colorScheme", scheme);
    setColorScheme(scheme);
  }

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        {user ? (
          <>
            <Route
              path="/resultados"
              exact
              render={() => (
                <Results
                  user={user}
                  color={color}
                  changeColorScheme={handleColorSchemeChange}
                />
              )}
            />
            {user.role === "admin" && (
              <Route
                path="/usuarios"
                exact
                render={() => (
                  <Users
                    user={user}
                    color={color}
                    changeColorScheme={handleColorSchemeChange}
                  />
                )}
              />
            )}
            {(user.role === "editor" || user.role === "admin") && (
              <Route
                path="/editar"
                exact
                render={() => (
                  <Edit
                    user={user}
                    color={color}
                    changeColorScheme={handleColorSchemeChange}
                  />
                )}
              />
            )}
            <Route
              path="/sair"
              exact
              render={() => (
                <Logout
                  user={user}
                  color={color}
                  changeColorScheme={handleColorSchemeChange}
                />
              )}
            />
          </>
        ) : (
          <>
            <Route path="/" exact component={Home} />
            <Route path="/password/recovery" component={PasswordRecovery} />
            <Route path="/recovery/code" component={RecoveryCode} />
            <Route path="/password/change" component={PasswordChange} />
          </>
        )}
      </Switch>
    </BrowserRouter>
  );
}
