export default function nonLocaleReplace(string, token) {
  if (!validInput(string, token)) return [{ type: "string", value: string }];

  let newString = "";
  let array = [];

  for (let i = 0; i < string.length; i += 1) {
    const subString = string.substring(i, i + token.length);

    if (subString.localeCompare(token, "en", { sensitivity: "base" }) === 0) {
      newString && array.push({ type: "string", value: newString });
      array.push({ type: "token", value: subString });
      i += subString.length - 1;
      newString = "";
    } else {
      newString += string.charAt(i);
    }
  }

  newString && array.push({ type: "string", value: newString });

  return array;
}

function validInput(string, token) {
  return (
    typeof string === "string" &&
    typeof token === "string" &&
    string.length &&
    string.length >= token.length
  );
}
