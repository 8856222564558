import React from "react";
import ReactDOM from "react-dom";
import { ToastProvider } from "react-toast-notifications";

import Routes from "./Routes";
import "./style.sass";
import "./config/colors.sass";

ReactDOM.render(
  <ToastProvider>
    <Routes />
  </ToastProvider>,
  document.getElementById("root")
);
