import React, { useState } from "react";
import { FiX } from "react-icons/fi";

import "./style.sass";

const roles = [
  ["Admin", "admin"],
  ["Editor", "editor"],
  ["Visualizador", "viwer"],
];

const defaults = {
  name: "",
  email: "",
  password: "",
  role: "",
};

export default function UserCreateModal({ onUserAction, color }) {
  const [values, setValues] = useState(defaults);

  function handleModalClick(e) {
    if (e.target.className === "user-create-modal") {
      onUserAction && onUserAction(false);
    }
  }

  function handelFormSubmit(e) {
    e.preventDefault();
    if (values.name !== values.name.trim()) {
      setValues({ ...values, name: values.name.trim() });
    } else {
      onUserAction && onUserAction(values);
    }
  }

  function handleInputChange(e) {
    const value = {};
    value[e.target.name] = e.target.value;
    setValues({ ...values, ...value });
  }

  return (
    <div className="user-create-modal" onClick={handleModalClick}>
      <div className="modal-wrapper">
        <div className="modal-container bg-light">
          <div
            className="close-button"
            onClick={() => onUserAction && onUserAction(false)}
            title="Fechar"
          >
            <FiX size="24" color={color} />
          </div>
          <form onSubmit={handelFormSubmit}>
            <header className="dark">Novo usuário</header>
            <div className="input-container">
              <label className="medium" htmlFor="name-input">
                Nome
              </label>
              <input
                className="bg-light medium border-medium border-medium-hover"
                required
                id="name-input"
                type="text"
                name="name"
                value={values.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-container">
              <label className="medium" htmlFor="email-input">
                Email
              </label>
              <input
                className="bg-light medium border-medium border-medium-hover"
                required
                id="email-input"
                type="email"
                name="email"
                value={values.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-container">
              <label className="medium" htmlFor="password-input">
                Password
              </label>
              <input
                className="bg-light medium border-medium border-medium-hover"
                required
                id="password-input"
                type="password"
                name="password"
                minLength="8"
                maxLength="64"
                value={values.password}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-container">
              <label className="medium" htmlFor="role-select">
                Cargo
              </label>
              <select
                className="bg-light medium border-medium border-medium-hover"
                required
                name="role"
                onChange={handleInputChange}
              >
                <option value=""></option>
                {roles.map((r) => (
                  <option key={r[1]} value={r[1]}>
                    {r[0]}
                  </option>
                ))}
              </select>
            </div>
            <button className="bg-light dark border-medium border-medium-hover">
              Criar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
