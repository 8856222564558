import React, { useState, useEffect } from "react";
import { FiX } from "react-icons/fi";

import "./style.sass";

export default function EditProfileModal({
  videoURL,
  communityURL,
  description,
  onProfileChange,
  profile,
  color,
}) {
  const [state, setState] = useState({
    video_url: videoURL,
    community_url: communityURL,
    description,
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      description: decodeURIComponent(escape(description)),
    }));
  }, [description]);

  function handleVideoURL(e) {
    setState({ ...state, video_url: e.target.value });
  }

  function handleCommunityURL(e) {
    setState({ ...state, community_url: e.target.value });
  }

  function handleDescription(e) {
    setState({ ...state, description: e.target.value });
  }

  function handleFormSubmit(e) {
    e.preventDefault();
    const values = {
      video_url: state.video_url.trim(),
      community_url: state.community_url.trim(),
      description: state.description,
    };

    if (onProfileChange) {
      onProfileChange(values);
    }
  }

  function handleModalClick(e) {
    if (e.target.className === "edit-profile-modal" && onProfileChange) {
      onProfileChange(null);
    }
  }

  return (
    <div className="edit-profile-modal" onClick={handleModalClick}>
      <div className="modal-wrapper bg-light">
        <div
          className="close-button"
          onClick={() => onProfileChange && onProfileChange(null)}
          title="Fechar"
        >
          <FiX size="24" color={color} />
        </div>
        <form onSubmit={handleFormSubmit}>
          <header className="form-header dark">Editar perfil {profile}</header>

          <div className="input-container">
            <label htmlFor="video-url" className="medium">
              Video URL
            </label>
            <input
              className="medium border-medium border-medium-hover"
              type="url"
              name="video_url"
              value={state.video_url}
              onChange={handleVideoURL}
              required
            />
          </div>

          <div className="input-container">
            <label htmlFor="video-url" className="medium">
              Community URL
            </label>
            <input
              className="medium border-medium border-medium-hover"
              type="url"
              name="community_url"
              value={state.community_url}
              onChange={handleCommunityURL}
              required
            />
          </div>

          <div className="input-container">
            <label htmlFor="description" className="medium">
              Descrição
            </label>
            <textarea
              className="medium border-medium border-medium-hover"
              type="url"
              name="description"
              onChange={handleDescription}
              required
              value={state.description}
            />
          </div>

          <button className="dark border-medium border-medium-hover">
            Salvar
          </button>
        </form>
      </div>
    </div>
  );
}
