/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";

import "./style.sass";
import api from "../../util/api";
import config from "../../config/config";

import Menu from "../../components/Menu";
import ResultsFilter from "../../components/ResultsFilter";
import ResultsTable from "../../components/ResultsTable";
import TablePagingFooter from "../../components/TablePagingFooter";
import DownloadTableButton from "../../components/DownloadTableButton";

function buildQuery(filters = {}, page) {
  const keys = Object.keys(filters);
  let query = "";

  keys.map((k, i) => {
    if (k !== "test" && filters[k]) {
      const value = formatValue(k, filters[k]);
      query += i ? `&${k}=${value}` : `${k}=${value}`;
    }
  });

  return query;
}

function formatValue(key, value) {
  if (key === "date_start") {
    return `${value} 00:00:00`;
  }

  if (key === "date_end") {
    return `${value} 23:59:59`;
  }

  return value;
}

const defaultFilters = {
  test: "enneagram",
  state: "",
  city: "",
  date_start: "",
  date_end: "",
  profile: "",
  points_min: "",
  points_max: "",
  page: "1",
  name: "",
};

export default function Results({ user, color, changeColorScheme }) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [results, setResults] = useState({});
  const [filters, setFilters] = useState(
    JSON.parse(sessionStorage.getItem("filters")) || defaultFilters
  );

  useEffect(() => {
    sessionStorage.setItem("filters", JSON.stringify(filters));
  }, [filters]);

  useEffect(() => {
    if (firstLoad || !filters.test) return;

    let monted = true;
    const query = buildQuery(filters);
    api
      .get(`/results/${filters.test}?${query}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      })
      .then((res) => {
        res.data.test = filters.test;
        monted && setResults(res.data);
        sessionStorage.setItem("results", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.error(err);
      });

    return () => (monted = false);
  }, [filters, firstLoad, results.test]);

  useEffect(() => {
    if (!firstLoad) return;

    const prevResults = sessionStorage.getItem("results");
    if (prevResults) {
      const data = JSON.parse(prevResults);
      setResults(data);
    }
    setFirstLoad(false);
  }, [firstLoad]);

  function handlePageChange(page) {
    setFilters({ ...filters, page });
  }

  function clearFilters() {
    setFilters(defaultFilters);
  }

  const query = buildQuery({
    ...filters,
    token: localStorage.getItem("sessionToken"),
  });

  const downloadURL = `${config.url.api}/results/download/${filters.test}?${query}`;

  return (
    <div className="results bg-medium">
      <Menu
        select="results"
        user={user}
        changeColorScheme={changeColorScheme}
      />
      <div className="content">
        <ResultsFilter
          values={filters}
          setValues={setFilters}
          clearFilters={clearFilters}
          color={color}
        />
        <div className="results-container">
          <DownloadTableButton color={color} downloadURL={downloadURL} />
          <ResultsTable
            results={results.results}
            test={results.test || filters.test}
            name={filters.name}
          />
          <TablePagingFooter {...results} onPageChange={handlePageChange} />
        </div>
      </div>
    </div>
  );
}
