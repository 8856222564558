import React, { useEffect } from "react";

import "./style.sass";
import next from "../../assets/icons/iconfinder_next_308956.svg";
import achiever from "../../assets/icons/iconfinder_medal_1118195.svg";
import challanger from "../../assets/icons/iconfinder_m-28_4230541.svg";
import dominance from "../../assets/icons/iconfinder_m-28_4230541.svg";
import enthusiast from "../../assets/icons/iconfinder_b-91_4230288.svg";
import investigator from "../../assets/icons/iconfinder_search_461380.svg";
import perfectionist from "../../assets/icons/iconfinder_diamond_430094.svg";
import romantic from "../../assets/icons/fire.png";
import loyalist from "../../assets/icons/iconfinder_Vector-icons_39_1041628.svg";
import mediator from "../../assets/icons/iconfinder_Meditation_4042272.svg";
import steadiness from "../../assets/icons/iconfinder_Meditation_4042272.svg";
import helper from "../../assets/icons/iconfinder_property-agent-005_1851811.svg";
import conscientiousness from "../../assets/icons/iconfinder_scales_296210.png";
import influence from "../../assets/icons/iconfinder_14_leader_target_achievement_business_3964125.png";

const icons = {
  next,
  achiever,
  challanger,
  dominance,
  enthusiast,
  investigator,
  perfectionist,
  romantic,
  loyalist,
  mediator,
  steadiness,
  helper,
  conscientiousness,
  influence,
};

function getIndex(profileType, profiles) {
  for (let i = 0; i < profiles.length; i += 1) {
    if (profiles[i][1] === profileType) return i;
  }
  return -1;
}

function getTypePortuguese(profileList, type) {
  const profile = profileList.find((p) => p[1] === type);
  return profile ? profile[0] : "";
}

export default function ProfilePreview({
  test,
  profile,
  profiles,
  onProfileChange,
  description,
  result = null,
}) {
  const answer = {
    percentage: 80,
    type: profile,
    points: 16,
    total: 20,
  };
  const index = getIndex(profile, profiles[test]);

  function handlePrevious() {
    if (onProfileChange) {
      const lastIndex = profiles[test].length - 1;
      const newIndex = index === 0 ? lastIndex : index - 1;
      const newProfile = profiles[test][newIndex][1];
      onProfileChange(newProfile);
    }
  }

  function handleNext() {
    if (onProfileChange) {
      const lastIndex = profiles[test].length - 1;
      const newIndex = index === lastIndex ? 0 : index + 1;
      const newProfile = profiles[test][newIndex][1];
      onProfileChange(newProfile);
    }
  }

  return (
    result && (
      <div className="profile-preview">
        <div className="result">
          <div
            className="header-container"
            data-precision={answer.percentage < 50 ? "low" : "high"}
          >
            <div className="top-container">
              <div className="percentage-profile-container">
                <div className="percentage-number">
                  {" "}
                  {answer.percentage + "%"}
                </div>
                <div className="profile">
                  {getTypePortuguese(profiles[test], profile)}
                </div>
              </div>
              <img
                className="profile-icon"
                src={icons[answer.type]}
                alt={answer.type}
              ></img>
            </div>
            <div className="score-container">
              <div className="score-background-bar">
                <div
                  className="score-bar"
                  style={{ width: answer.percentage + "%" }}
                ></div>
              </div>
              <div className="score-fraction">
                {answer.points + " / " + answer.total}
              </div>
            </div>
          </div>
          {/* <iframe
            title={result.profile}
            src={result.video_url}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe> */}
          <div className="description">
            <p dangerouslySetInnerHTML={{ __html: description }}></p>
          </div>
        </div>
        <div className="controls-container">
          <img
            onClick={handlePrevious}
            className="paging-button previous-button"
            alt="previous"
            title="Personalidade anterior"
            src={icons.next}
            data-status={index === 0 ? "disabled" : ""}
          />
          <span className="paging">
            {index + 1} / {profiles[test].length}
          </span>
          <img
            onClick={handleNext}
            className="paging-button next-button"
            alt="next"
            title="Próxima personalidade"
            src={icons.next}
            data-status={index === profiles[test].length ? "disabled" : ""}
          />
        </div>
      </div>
    )
  );
}
