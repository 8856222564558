import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { ImParagraphJustify } from "react-icons/im";
import { FiUsers } from "react-icons/fi";
import { FiLogOut } from "react-icons/fi";
import { BiPalette } from "react-icons/bi";

import isDescendent from "../../util/isDescendent";
import colors from "../../config/colors";
import "./style.sass";

const menuIconColor = "#fff";

const defaults = {
  names: {
    results: "Resultados",
    edit: "Editar",
    users: "Usuários",
    logout: "Sair",
    palette: "Cores",
  },
  routes: {
    results: "resultados",
    edit: "editar",
    users: "usuarios",
    logout: "sair",
    palette: "",
  },
};

export default function Menu({
  select = "home",
  names = defaults.names,
  routes = defaults.routes,
  user,
  changeColorScheme,
}) {
  const [redirectTo, setRedirectTo] = useState("");
  const [showPalette, setShowPalette] = useState(false);

  function handleClick(route) {
    if (route) {
      setRedirectTo(route);
    }
  }

  function handleSchemeChange(scheme) {
    if (colors[scheme]) {
      changeColorScheme(scheme);
    }
  }

  function toggleShowPalette() {
    setShowPalette(!showPalette);
  }

  useEffect(() => {
    if (!showPalette) return;

    function handleClick(e) {
      const palette = document.querySelector(".palette-wrapper");
      if (e.target === palette || isDescendent(e.target, palette)) return;

      setShowPalette(false);
    }

    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, [showPalette]);

  return redirectTo ? (
    <Redirect to={redirectTo} />
  ) : (
    <div className="menu bg-dark">
      <div className="buttons-wrapper">
        <button
          className="menu-button reverse"
          data-selected={select === "results"}
          onClick={() => handleClick(routes.results)}
        >
          <ImParagraphJustify size="24" color={menuIconColor} />
          <span>{names.results || defaults.names.results}</span>
        </button>
        {user && (user.role === "admin" || user.role === "editor") && (
          <button
            className="menu-button reverse"
            data-selected={select === "edit"}
            onClick={() => handleClick(routes.edit)}
          >
            <FiEdit size="24" color={menuIconColor} />
            <span>{names.edit || defaults.names.edit}</span>
          </button>
        )}
        {user && user.role === "admin" && (
          <button
            className="menu-button reverse"
            data-selected={select === "users"}
            onClick={() => handleClick(routes.users)}
          >
            <FiUsers size="24" color={menuIconColor} />
            <span>{names.users || defaults.names.users}</span>
          </button>
        )}
        <div className="palette-wrapper">
          <button className="menu-button logout-button reverse" onClick={toggleShowPalette}>
            <BiPalette size="24" color={menuIconColor} />
            <span>{names.palette || defaults.names.palette}</span>
          </button>
          <div className="palette bg-light" data-show-palette={showPalette}>
            <button
              style={{ backgroundColor: colors.default }}
              onClick={() => handleSchemeChange("default")}
            />
            <button
              style={{ backgroundColor: colors.blue }}
              onClick={() => handleSchemeChange("blue")}
            />
            <button
              style={{ backgroundColor: colors.purple }}
              onClick={() => handleSchemeChange("purple")}
            />
            <button
              style={{ backgroundColor: colors.brown }}
              onClick={() => handleSchemeChange("brown")}
            />
          </div>
        </div>
      </div>

      <button
        className="menu-button logout-button reverse"
        data-selected={select === "logout"}
        onClick={() => handleClick(routes.logout)}
      >
        <FiLogOut size="24" color={menuIconColor} />
        <span>{names.logout || defaults.names.logout}</span>
      </button>
    </div>
  );
}
