/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import api from "../../util/api";
import "./style.sass";

export default function Home() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { addToast } = useToasts();

  function handleFormSubmit(event) {
    event.preventDefault();

    api
      .post("/session", { email, password })
      .then((res) => {
        const { token } = res.data;
        localStorage.setItem("sessionToken", token);
        window.location.href = `/resultados`;
      })
      .catch((err) => {
        addToast("Email e senha não conferem", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  }

  function handlePassword(event) {
    setPassword(event.target.value.replace(/\s/g, ""));
  }

  return (
    <div className="home">
      <h1>Acesso à área administrativa</h1>
      <div className="form-wrapper">
        <form onSubmit={handleFormSubmit}>
          <input
            required
            type="email"
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <input
            required
            type="password"
            placeholder="Senha"
            value={password}
            minLength={8}
            maxLength={64}
            onChange={handlePassword}
          />
          <input type="submit" value="Entrar" />
        </form>
        <Link to="/password/recovery">Esqueceu sua senha?</Link>
      </div>
    </div>
  );
}
