/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import { FiUserPlus } from "react-icons/fi";
import { FiUserMinus } from "react-icons/fi";

import api from "../../util/api";
import formatDate from "../../util/formatDate";
import "./style.sass";

import Menu from "../../components/Menu";
import UserDeleteModal from "../../components/UserDeleteModal";
import UserCreateModal from "../../components/UserCreateModal";

const tableHeaders = [
  ["Nome", "name"],
  ["Email", "email"],
  ["Permissão", "role", "centered"],
  ["Último acesso", "last_access", "centered"],
  ["\u00A0", "delete", ""],
];

const roles = [
  ["Admin", "admin"],
  ["Editor", "editor"],
  ["Visualizador", "viwer"],
];

export default function Users({ user, color, changeColorScheme }) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [users, setUsers] = useState([
    { ...user, ...{ last_access: formatDate(user.last_access) } },
  ]);
  const [userDelete, setUserDelete] = useState(null);
  const [userCreate, setUserCreate] = useState(false);
  const [newUser, setNewUser] = useState(null);

  useEffect(() => {
    if (!firstLoad) return;

    const prevUsers = sessionStorage.getItem("users");
    if (prevUsers) {
      const data = JSON.parse(prevUsers);
      setUsers(data);
    }
    setFirstLoad(false);
  }, [firstLoad]);

  useEffect(() => {
    if (firstLoad) return;

    let monted = true;

    api
      .get("/user", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      })
      .then((res) => {
        const { users } = res.data;
        if (users && monted) {
          users.map((u) => {
            return (u.last_access = formatDate(u.last_access));
          });
          setUsers(users);
          sessionStorage.setItem("users", JSON.stringify(users));
        }
      })
      .catch((err) => {
        console.warn(err);
      });
    return () => (monted = false);
  }, [firstLoad]);

  function handleUserDelete(confirmDelete) {
    setUserDelete(null);

    if (!confirmDelete) return;

    api
      .delete(`/user/${userDelete.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      })
      .then(() => {
        const newUserList = users.filter((u) => u.id !== userDelete.id);
        setUsers(newUserList);
      })
      .catch((err) => {
        console.warn(err);
      });
  }

  useEffect(() => {
    if (!newUser) return;

    let mounted = true;

    api
      .post("/user", newUser, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      })
      .then(() => {
        api
          .get("/user", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          })
          .then((res) => {
            if (!mounted) return;

            res.data.users.map((u) => {
              return (u.last_access = formatDate(u.last_access));
            });
            setUsers(res.data.users);
          });
      });

    return () => (mounted = true);
  }, [newUser]);

  function handleUserCreate(user) {
    setUserCreate(false);
    if (user) {
      setNewUser(user);
    }
  }

  return (
    <div className="users bg-medium">
      {userDelete && (
        <UserDeleteModal
          onUserAction={handleUserDelete}
          user={userDelete}
          color={color}
        />
      )}
      {userCreate && (
        <UserCreateModal onUserAction={handleUserCreate} color={color} />
      )}
      <Menu select="users" user={user} changeColorScheme={changeColorScheme} />
      <div className="content">
        <div
          className="new-button bg-light border-light border-light-hover"
          onClick={() => setUserCreate(true)}
        >
          <FiUserPlus size="20" color={color} />
          <span className="dark">Novo usuário</span>
        </div>
        <div className="users-table">
          <div className="table-container">
            {tableHeaders.map((h) => (
              <div key={h[1]} className="column">
                <div className="header bg-light dark">{h[0]}</div>
                {users.map((u) => (
                  <div
                    key={`${h[1]}-${u.id}`}
                    className={`cell bg-light medium ${h[2] || ""}`}
                  >
                    {h[1] === "delete" ? (
                      <FiUserMinus
                        size="18"
                        color={color}
                        title="Excluir usuário"
                        className={`${
                          user.id === u.id && h[1] === "delete"
                            ? "disabled"
                            : ""
                        }`}
                        onClick={() => setUserDelete(u)}
                      />
                    ) : h[1] === "role" ? (
                      roles.find((r) => r[1] === u[h[1]])[0]
                    ) : (
                      u[h[1]]
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
